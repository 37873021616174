/**
 * Description: Common functions for all pages
 */

// Enable debug messages when running on localhost or rocktime.net
const debugMessagesEnabled = window.location.href.includes('localhost') || window.location.href.includes('rocktime.net');

// Cookie settings
const acceptedCookieValues = ['AcceptedTypes=ALL', 'AcceptedTypes=Necessary,Tracking', 'AcceptedTypes=Necessary'];
const privacyCookieName = 'rtCookiePrivacySetting';

// Cookie popup elements
var cookieBarAcceptAllButton = null;
var cookiePopupSaveSettingsButton = null;
var cookiePopupAcceptAllButton = null;
var cookieButtons = [];

/**
 * Initializes the cookie popup buttons if the cookie popup has not already been accepted.
 */
function initCookieButtons() {
    cookieBarAcceptAllButton = document.getElementById('CookiePolicyAcceptAll');
    cookiePopupSaveSettingsButton = document.getElementById('btnSaveOptions');
    cookiePopupAcceptAllButton = document.getElementById('btnAcceptAll');
    cookieButtons.push(cookieBarAcceptAllButton, cookiePopupSaveSettingsButton, cookiePopupAcceptAllButton);
    let cookieFound = false;
    acceptedCookieValues.forEach(value => {
        if (checkCookieExistsWithValue(privacyCookieName, value)) {
            debug('Cookie popup already accepted.');
            cookieFound = true;
            return;
        }
    });
    if (!cookieFound) {
        addCookiePopupEventListeners();
    }
}

/**
 * Adds event listeners to the cookie popup buttons.
*/
function addCookiePopupEventListeners() {
    debug('Initializing cookie buttons...');
    cookieButtons.forEach(button => {
        if (button) {
            button.addEventListener("click", checkCookieAndReload);
        }
    });
}

/**
 * Accepts all cookies and reloads the page when the desired cookie value is found.
 */
function checkCookieAndReload() {
    debug('Waiting for cookie creation...');
    waitForCookieValue(privacyCookieName, acceptedCookieValues, 1000)
        .then(() => {
            debug('Cookie found. Reloading page...');
            location.reload();
        })
        .catch((error) => {
            console.error('Error occurred while waiting for cookie:', error);
        });
}

/**
 * Waits for any value from the acceptedCookieValues array to be set in the specified cookie.
 * @param {string} cookieName - The name of the cookie to wait for.
 * @param {string[]} acceptedCookieValues - An array of accepted cookie values.
 * @param {number} [interval=1000] - The interval (in milliseconds) at which to check the cookie value.
 * @returns {Promise} A promise that resolves when any value from the acceptedCookieValues array is found in the cookie.
 */
function waitForCookieValue(cookieName, acceptedCookieValues, interval = 1000) {
    debug('Waiting for cookie value...');
    debug('Cookie name:', cookieName);
    debug('Accepted values:', acceptedCookieValues);
    return new Promise((resolve, reject) => {
        const checkCookie = () => {
            const cookieValue = getCookie(cookieName);
            const foundValue = acceptedCookieValues.find(value => cookieValue.includes(value));
            if (foundValue) {
                resolve(foundValue);
            } else {
                setTimeout(checkCookie, interval);
            }
        };
        checkCookie();
    });
}

/**
 * Gets the value of the specified cookie.
 * @param {string} cookieName - The name of the cookie.
 * @returns {string} The value of the cookie.
 */
function getCookie(cookieName) {
    debug('Getting cookie value...');
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
}

/**
 * Checks if a cookie exists with the specified name and value.
 * @param {string} cookieName - The name of the cookie to check.
 * @param {string} targetValue - The value to check for.
 * @returns {boolean} True if a cookie with the specified name and value exists, otherwise false.
 */
function checkCookieExistsWithValue(cookieName, targetValue) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if this cookie starts with the specified name
        if (cookie.startsWith(cookieName + '=')) {
            const cookieValue = cookie.substring(cookieName.length + 1);
            // Check if the cookie value matches the target value
            if (cookieValue === targetValue) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Logs a debug message to the console.
 * @param {string} message - The message to log.
 */
function debug(message) {
    if (debugMessagesEnabled) {
        console.log(message);
    }
}