var isMobile = false;
var BasketOpen = false;
var mobileWidth = 768;

$(document).ready(function () {
    mobileCheck();
    initialise();
    footerLinksToggle(isMobile);
    popBasket(isMobile);
    stopInputFocusZoom();
    disableCopyPaste();
    responsiveYouTubeVideo();
});

$(window).on('resize', function () {
    changeEvents();
});

$(window).on('orientationchange', function () {
    changeEvents();
});

function changeEvents() {
    mobileCheck();
    initialise();
    footerLinksToggle(isMobile);
    popBasket(isMobile);
}

function mobileCheck() {
    if ($(window).width() <= mobileWidth) {
        isMobile = true;
    }
    else {
        isMobile = false;
    }
}

function footerLinksToggle(isMobile) {
    $('footer h4').unbind('click');
    if (isMobile) {
        $('.footer-mobile-toggle').hide();
        $('footer h4').click(function () {
            $(this).next('.footer-mobile-toggle').slideToggle();
        });
    }
    else {
        $('.footer-mobile-toggle').show();
    }
}

function stopInputFocusZoom() {
    var inputFocus = false;
    var metaViewport = $('meta[name=viewport]');
    var metaViewportOriginalContent = metaViewport.attr('content');
    $('input[type=text], input[type=password], input[type=email]').focus(function () {
        if (!inputFocus) {
            metaViewport.attr('content', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=0');
            inputFocus = true;
        }
    }).blur(function () {
        metaViewport.attr('content', metaViewportOriginalContent);
        inputFocus = false;
    });
}

function disableCopyPaste() {
    $('input.disablecopypaste, .disablecopypaste input').bind('copy paste', function (e) {
        alert('Please type your email address');
        e.preventDefault();
    });
}

function scrollToColorbox() {
    $('#colorbox').bind('DOMSubtreeModified', function () {
        if ($('#colorbox:visible').length > 0) {
            var scrollTo = ($(window).height() / 2) - ($('#colorbox').height() / 2);
            $('html, body').stop(true, true).animate({
                scrollTop: scrollTo
            }, 1000);
        }
    });
}

function responsiveYouTubeVideo() {
    if (isMobile) {
        // each iframe on page
        $('iframe').each(function () {
            var thisSrc = $(this).attr('src')
            // this Source contains a youtube link
            if (thisSrc.indexOf('youtu') || thisSrc.indexOf('youtube')) {
                $(this).wrap('<div class="videoWrapper"></div>');
            }
        });
    }
}

function clearEmail(obj) {
    obj.value = "";
}

function popBasket(isMobile) {
    if ((isMobile) && ($(window).width() <= 768)) {
        $('#pop-basket').show();
    }
    else {
        $('#pop-basket').hide();
    }
}

var BasketPop = function () {
    if (!isMobile) {
        $('#pop-basket').stop(true, true).fadeIn(500);
        BasketOpen = true;
    }
}

var BasketPopOff = function () {
    if (!isMobile) {
        setTimeout("BasketClose()", 100);
        BasketOpen = false
    }
}

var BasketPopKeep = function () {
    BasketOpen = true;
}

var BasketClose = function () {
    if (BasketOpen == false) {
        $('#pop-basket').fadeOut(500);
    }
}

function DeliveryPop() {
    document.getElementById('pop-delivery').style.display = "block";
    DeliveryOpen = true;
}

function DeliveryPopOff() {
    setTimeout("DeliveryClose()", 100);
    DeliveryOpen = false
}

function DeliveryPopKeep() {
    DeliveryOpen = true;
}

function DeliveryClose() {
    if (DeliveryOpen == false) {
        document.getElementById('pop-delivery').style.display = "none";
    }
}

function is_int(value) {
    if ((parseFloat(value) == parseInt(value)) && !isNaN(value)) {
        return true;
    } else {
        return false;
    }
}

function imageSwap(image, variationid, imageid, imagefullsize) {
    $('#productimage').css('display', 'block');
    $('#productvideo1').css('display', 'none');
    $('#productvideo2').css('display', 'none');
    $('#productvideo3').css('display', 'none');
    document.getElementById('productimage').innerHTML = '<a class="colorbox" href="' + image + '"><img src="' + image + '" alt="LockShop" class="img-responsive"  style="display:inherit;" /></a>';
    $('.colorbox').colorbox({ maxWidth: '80%' });
}

function showVideo(imageid) {
    $('#productimage').css('display', 'none');
    $('#enlargeImage').css('display', 'none');
    $('#productvideo1').css('display', 'none');
    $('#productvideo2').css('display', 'none');
    $('#productvideo3').css('display', 'none');
    $('#productvideo' + imageid).css('display', 'block');
}

function SetUniqueRadioButton(nameregex, current) {
    re = new RegExp(nameregex);
    for (i = 0; i < document.forms[0].elements.length; i++) {
        elm = document.forms[0].elements[i]
        if (elm.type == 'radio') {
            if (re.test(elm.name)) {
                elm.checked = false;
            }
        }
    }
    current.checked = true;
}

function hideMenu() {
    $('.rt-submenu').each(function () {
        $(this).removeClass('rt-submenu-open');
    });
}

function initialise() {
    var currheight;
    var currwidth;

    ResizeMenuHeights();

    function ResizeMenuHeights() {
        if ($(window).width() > 768) {
            var blogClasses = [".MenuSameHeight", ".MenuSameHeight1", ".MenuSameHeight2", ".MenuSameHeight3", ".MenuSameHeight4", ".MenuSameHeight5", ".MenuSameHeight6", ".MenuSameHeight7", ".MenuSameHeight8", ".MenuSameHeight9"];
            var blogClass;
            var blogMaxHeight = 312;
            for (blogClass in blogClasses) {

                blogMaxHeight = 0;

                $(blogClasses[blogClass]).each(function () {
                    $(this).height('auto');
                });

                $(blogClasses[blogClass]).each(function () {

                    if ($(this).actual('height') > blogMaxHeight) { blogMaxHeight = $(this).actual('height'); }
                });

                $(blogClasses[blogClass]).each(function () {
                    $(this).height(blogMaxHeight);
                });
            }
        } else {
            var blogClasses = [".MenuSameHeight", ".MenuSameHeight1", ".MenuSameHeight2", ".MenuSameHeight3", ".MenuSameHeight4", ".MenuSameHeight5", ".MenuSameHeight6", ".MenuSameHeight7", ".MenuSameHeight8", ".MenuSameHeight9"];
            var blogClass;
            for (blogClass in blogClasses) {
                $(blogClasses[blogClass]).each(function () {
                    $(this).height('auto');
                });
            }
        }
    }

    $('.tabletMenu').unbind('click');
    $('.tabletMenu').click(function () {

        var menu = $(this).parent().find('.rt-submenu');
        if (menu.hasClass('rt-submenu-open')) {
            hideMenu();
        }
        else {
            hideMenu();
            menu.addClass('rt-submenu-open');
        }
    });

    if ($(window).width() <= 768) {
        $('.rt-nav-toggle').on('click', function () {
            var thisHref = $(this).attr('href');
            $('.rt-submenu').slideUp();
            $('.rt-menu-dropdown').removeClass('rt-menu-dropdown-active');
            $('.rt-nav-toggle').removeClass('rt-nav-toggle-active');
            if ($(thisHref).is(':hidden')) {
                $(this).addClass('rt-nav-toggle-active');
                $('.rt-nav-toggled, .rt-submenu').slideUp();
            }
            $(thisHref).stop(true, true).delay(500).slideToggle();
            return false;
        });

        $('.rt-menu > li > a').each(function () {
            if ($(this).parent('li').find('.rt-submenu').length > 0) {
                $(this).addClass('rt-menu-dropdown');
            }
        });

        $('.rt-menu-dropdown').off('click');
        $('.rt-menu-dropdown').on('click', function () {
            if (!$(this).hasClass('rt-menu-dropdown-active')) {
                var submenuHeight = 0;
                var previousSubmenuVisible = $(this).parent('li').prevAll('li').find('.rt-submenu:visible');
                if (previousSubmenuVisible.length > 0) {
                    submenuHeight = previousSubmenuVisible.outerHeight(true);
                }
                $('.rt-submenu').slideUp(250);
                $(this).next('.rt-submenu').stop(true, true).slideDown();
                var thisOffset = $(this).offset().top - submenuHeight;
                $('html, body').delay(250).stop(true, true).animate({
                    scrollTop: thisOffset
                }, 1000);
                $('.rt-menu-dropdown').removeClass('rt-menu-dropdown-active');
                $(this).addClass('rt-menu-dropdown-active');
            }
            else {
                $(this).next('.rt-submenu:visible').slideUp();
                $(this).removeClass('rt-menu-dropdown-active');
            }
            return false;
        });
    } else {
        $('.rt-nav').show();
        $('.rt-nav-toggle').off('click');
        $('.rt-menu-dropdown').off('click');
        $('.rt-submenu').removeAttr('style');
        $('.rt-nav').removeAttr('style');
    }
}